import axios from '@axios'

let phaseData = ''
let subPhaseData = ''
let activityData = ''
let programData = ''

const data = {
  data: [],
}

function myFunction() {
  phaseData = JSON.parse(sessionStorage.getItem('phaseData'))
  subPhaseData = JSON.parse(sessionStorage.getItem('subPhaseData'))
  activityData = JSON.parse(sessionStorage.getItem('activityData'))
  programData = JSON.parse(sessionStorage.getItem('programData'))
  data.data = []
  for (let i = 0; i < phaseData.length; i += 1) {
    data.data.push({
      id: phaseData[i].id + 1,
      url: '',
      title: phaseData[i].title,
      start: new Date(phaseData[i].begindate_timestamp),
      end: new Date(phaseData[i].enddate_timestamp),
      allDay: true,
      extendedProps: {
        calendar: 'Phases',
      },
    })
  }
  for (let i = 0; i < subPhaseData.length; i += 1) {
    data.data.push({
      id: subPhaseData[i].id + 1,
      url: '',
      title: subPhaseData[i].title,
      start: new Date(subPhaseData[i].begindate_timestamp),
      end: new Date(subPhaseData[i].enddate_timestamp),
      allDay: true,
      extendedProps: {
        calendar: 'SubPhases',
      },
    })
  }
  for (let i = 0; i < activityData.length; i += 1) {
    data.data.push({
      id: activityData[i].id + 1,
      url: '',
      title: activityData[i].title,
      start: new Date(activityData[i].begindate_timestamp),
      end: new Date(activityData[i].enddate_timestamp),
      allDay: true,
      extendedProps: {
        calendar: 'Activities',
      },
    })
  }
  data.data.push({
    id: 0,
    url: '',
    title: programData.title,
    start: new Date(programData.begin_date),
    end: new Date(programData.end_date),
    allDay: true,
    extendedProps: {
      calendar: 'Program',
    },
  })
}

window.addEventListener('storage', myFunction)

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Program',
      },
      {
        color: 'info',
        label: 'Phases',
      },
      {
        color: 'success',
        label: 'SubPhases',
      },
      {
        color: 'warning',
        label: 'Activities',
      },
    ],
    selectedCalendars: ['Program', 'Phases', 'Activities', 'SubPhases'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents() {
      return new Promise(resolve => {
        resolve(data)
        /*
        axios
          .get('/apps/calendar/events', {
            params: {
              calendars: calendars.join(','),
            },
          })
          .then(response => {
            resolve(data)
          })
          .catch(error => reject(error))
          */
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
