<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >

      <app-collapse-item
        class="text-left"
        title="Schedule Program"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Start & End date"
              label-for="programSchedule"
            >
              <flat-pickr
                id="programSchedule"
                v-model="scheduler.programSchedule"
                :config="{ mode: 'range', altInput: true, altFormat: 'F j, Y'}"
                class="form-control"
                placeholder="Select Start & End Date"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-collapse-item>

      <app-collapse-item
        class="text-left"
        title="Schedule Buffer"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Start & End date"
              label-for="bufferSchedule"
            >
              <flat-pickr
                id="bufferSchedule"
                v-model="scheduler.bufferSchedule"
                :config="{ mode: 'range', minDate: scheduler.programSchedule && scheduler.programSchedule.split('to')[1], altInput: true, altFormat: 'F j, Y'}"
                class="form-control"
                placeholder="Select Start & End Date"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <button
          aria-controls="sidebar-add-new-event"
          aria-expanded="false"
          type="button"
          class="btn btn-primary btn-block"
          style="position: relative; width: 200px"
          @click="addDate"
        > Add Date </button>
      </app-collapse-item>
    </app-collapse>
    <b-card
      class="mt-2"
    >
      <b-card-title class="lead">
        Schedule Phases/Activities
      </b-card-title>
      <calendar />
    </b-card>
  </div>
</template>
<script>
import Calendar from '@/views/programs/programManager/components/calendar/Calendar.vue'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import gql from 'graphql-tag'
import {
  BCard,
  BCardTitle,
  BCol,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCol,
    BFormGroup,
    BRow,
    Calendar,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      dateValues: {
        begin_date: '',
        buffer_begin_date: '',
        buffer_end_date: '',
        end_date: '',
      },
      scheduler: {
        programSchedule: null,
        bufferSchedule: null,
      },
    }
  },
  methods: {
    addDate() {
      this.dateValues.begin_date = this.scheduler.programSchedule.slice(0, 10)
      this.dateValues.end_date = this.scheduler.programSchedule.slice(14, 24)
      this.dateValues.buffer_begin_date = this.scheduler.bufferSchedule.slice(0, 10)
      this.dateValues.buffer_end_date = this.scheduler.bufferSchedule.slice(14, 24)
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation(
          $dateInput: programs_basicinfo_set_input!
        ) {
        update_programs_basicinfo(where: {id: {_eq: ${this.$route.params.id}}},
          _set: $dateInput) {
          affected_rows
          returning {
            id
          }
        }
      }`,
        variables: {
          dateInput: this.dateValues,
        },
      })
    },
  },
}
</script>
