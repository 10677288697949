<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search startups"
          type="text"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              dropright
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="`/programs/viewAssignment/${props.row.id}`">
                <feather-icon
                  class="mr-50"
                  icon="EyeIcon"
                />
                <span>View Details</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-overlay>
</template>
<script>
import {
  BFormInput,
  BFormGroup, BPagination, BFormSelect, BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BOverlay,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      searchTerm: '',
      rounds: [
        { title: null },
      ],
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Name',
          field(row) {
            if (!row.programs_applicantstable) return '-'
            return row.programs_applicantstable.name || '-'
          },
        },
        {
          label: 'Startup',
          field(row) {
            if (!row.programs_applicantstable) return '-'
            return row.programs_applicantstable.startup || '-'
          },
        },
        {
          label: 'Progress',
          field(row) {
            if (!row.programs_progressstagetable) return '-'
            return row.programs_progressstagetable.title || '-'
          },
        },
      ],
      rows: [],
    }
  },
  computed: {
    applicationLink() {
      if (!this.rows[0]) return true
      return `${window.location.origin}/programs/applicationQuestions/${this.$route.params.id}/${this.rows[0].id}/6`
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_startupparticipants(where: {programs_applicantstable: {program_id: {_eq: ${this.$route.params.id}}}}){
            id
            programs_applicantstable {
              name
              startup
            }
            programs_progressstagetable {
              title
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants,
    },
  },
}
</script>
