<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search progress stages"
          type="text"
        />
        <b-button
          v-b-modal.progressStageModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Progress Stage
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              dropright
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  class="mr-50"
                  icon="EditIcon"
                />
                <span>Modify</span>
              </b-dropdown-item>
              <b-dropdown-item variant="danger">
                <feather-icon
                  class="mr-50"
                  icon="PauseIcon"
                />
                <span>Deactivate</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!--Column : Stage Action -->
        <span v-else-if="props.column.field === 'startups'">
          <b-button
            variant="primary"
            size="sm"
            :to="`/programs/progress/${props.row.id}/startups`"
          >View Startups</b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="progressStageModal"
      size="lg"
      title="Add Progress Stage"
      ok-title="Save"
      @ok="addAssignment"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Title"
            label-size="sm"
          >
            <b-form-input
              v-model="progressStageTitle"
              type="text"
              placeholder="Progress Stage Title"
              required
            /></b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Operation"
            label-size="sm"
          ><treeselect
            v-model="selectedOperation"
            :multiple="true"
            :normalizer="normalizer"
            :options="operations"
            placeholder="Select Phase, Subphase or Activities"
          /></b-form-group></b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>
<script>

import {
  BButton, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    // vSelect,
    Treeselect,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Operations',
          field(row) {
            if (!row.programs_operationstables.length) return '-'
            return row.programs_operationstables.map(e => e.programs_operationstable.title)
              .join(', ')
          },
        },
        {
          label: 'Startups',
          field: 'startups',
          sortable: false,
        },
      ],
      rows: [],
      progressStageTitle: null,
      selectedOperation: [],
    }
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    addAssignment() {
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [programs_operationstable_insert_input!]!) {
              insert_programs_progressstagetable_one(object: {title: ${this.progressStageTitle}, programs_operationstables: {data: $data}}) {
                id
              }
            }`,
          variables: {
            data: this.selectedOperation.map(e => ({ operations_id: e, program_id: this.$route.params.id })),
          },
          update: () => {
            this.$apollo.queries.rows.refetch()
          },
        },
      )
      this.progressStageTitle = null; this.selectedOperation = null
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_progressstagetable(where: {programs_operationstables: {program_id: {_eq: ${this.$route.params.id}}}}) {
            id
            title
            programs_operationstables {
              programs_operationstable {
                 title
                }
              }
            }
          }`
      },
      update: data => data.programs_progressstagetable,
    },
    operations: {
      query() {
        return gql`{
            programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "Phase"}}) {
                  id
                  title
                  programs_operationstables(where: {operations_type: {_eq: "SubPhase"}}) {
                    id
                    title
                    programs_operationstables {
                      id
                      title
                    }
                  }
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>
