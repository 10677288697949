<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >
      <app-collapse-item
        class="text-left"
        title="Application Editor"
      >
        <b-table
          :items="rounds"
          striped
          borderless
          :fields="['ID', 'rounds', 'actions']"
        >
          <template #cell(ID)="row">
            {{ row.index+1 }}
          </template>
          <template #cell(rounds)="row">
            <b-form-input v-model="row.item.title" />
          </template>
          <template #cell(actions)="row">
            <b-button-group>
              <b-button
                variant="outline-primary"
                @click="selectedRound = row.index; $bvModal.show('appModal')"
              >
                Edit Application Form
              </b-button>
              <b-button
                variant="outline-primary"
                @click="selectedRound = row.index; $bvModal.show('reviewModal')"
              >
                Edit Review Form
              </b-button>
            </b-button-group>
            <b-button
              class="ml-1"
              variant="outline-danger"
              :disabled="rounds.length < 2"
              @click="deleteRound(row.index)"
            >
              Delete Round
            </b-button>
          </template>
        </b-table>
        <b-button
          variant="primary"
          class="mt-1"
          @click="addRound()"
        ><feather-icon
          class="mr-25"
          icon="PlusIcon"
        />
          Add Round
        </b-button>
      </app-collapse-item>
      <app-collapse-item
        class="text-left"
        title="Call for Applications"
      >
        <b-table
          :items="rounds"
          striped
          borderless
          :fields="['ID', 'rounds', 'link']"
        >
          <template #cell(ID)="row">
            {{ row.index+1 }}
          </template>
          <template #cell(rounds)="row">
            {{ row.item.title }}
          </template>
          <template #cell(link)="row">
            <b-button
              variant="outline-primary"
              size="sm"
              :to="`/programs/application/${$route.params.id}/${row.item.id}`"
            >
              Open Application
            </b-button>
          </template>
        </b-table>
      </app-collapse-item>
      <app-collapse-item
        title="Target Audience"
      >
        <b-button
          class="mx-50"
          variant="outline-primary"
        >
          Import from Networks
        </b-button>
        or
        <b-button
          class="mx-50"
          variant="outline-success"
        >
          <feather-icon
            class="mr-25"
            icon="UploadIcon"
          />
          Upload CSV
        </b-button>
      </app-collapse-item>
    </app-collapse>
    <b-overlay :show="$apollo.loading"><b-card
      class="mt-2 text-left"
      title="View Applicants"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search applicants"
            type="text"
          />
        </b-form-group>

      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <b-avatar
              :src="props.row.avatar"
              class="mx-1"
            />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!--                    Column: Status-->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                dropright
                no-caret
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
              >
                <template v-slot:button-content>
                  <feather-icon
                    class="text-body align-middle"
                    icon="MoreVerticalIcon"
                    size="16"
                  />
                </template>
                <b-dropdown-item :to="`/programs/viewApplication/${$route.params.id}/${props.row.id}`">
                  <feather-icon
                    class="mr-50"
                    icon="EyeIcon"
                  />
                  <span>View Applications</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card></b-overlay>
    <b-modal
      id="appModal"
      size="lg"
      title="Application Form"
    >
      <form-builder
        v-model="rounds[selectedRound].applicationForm"
        :inherited-sections="sectionObject(rounds[selectedRound].programs_applicationsquestionstables, 'section')"
      />
    </b-modal>
    <b-modal
      id="reviewModal"
      size="lg"
      title="Review Form"
    >
      <form-builder
        v-model="rounds[selectedRound].reviewForm"
        :inherited-sections="sectionObject(rounds[selectedRound].programs_applicationreviewquestionnairetables, 'section')"
      />
    </b-modal>
  </div>
</template>
<script>
import FormBuilder from '@/views/programs/programManager/components/applicationManager/FormBuilder.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BBadge,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BPagination,
  BTable,
  BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BButton,
    BFormInput,
    BCard,
    BFormGroup,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BModal,
    BTable,
    BButtonGroup,
    FormBuilder,
    BOverlay,
  },
  data() {
    return {
      rounds: [
        { title: '' },
      ],
      selectedRound: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'name',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Application',
          field(row) {
            if (!row.programs_applicationformstable) return '-'
            return row.programs_applicationformstable.title
          },
        },
        {
          label: 'Progress',
          field: 'Progress',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    // TODO: Connect to DB
    addRound() {
      this.rounds.push({ title: 'New Round' })
    },
    deleteRound(i) {
      this.$bvModal.msgBoxConfirm('Are you sure? This action cannot be undone!')
        .then(value => {
          if (value) this.rounds.splice(i, 1)
        })
    },
    sectionObject(array, key) {
      if (array === undefined) return false
      const arr = Array.from(array).reduce((hash, obj) => {
        if (obj[key] === undefined) return hash
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
      }, {})
      const obj = []
      Object.entries(arr).forEach(([k, v]) => obj.push({ title: k, items: v }))
      return obj
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_applicantstable(where:{program_id: {_eq: ${this.$route.params.id}}}){
            id
            program_id
            name
            programs_applicationformstable{
              title
            }
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
    rounds: {
      query() {
        return gql`
        {
          programs_applicationformstable(where:{program_id: {_eq: ${this.$route.params.id}}}){
            id
            round_number
            title
            programs_applicationsquestionstables {
              id
              question
              section
              answer_type
            }
            programs_applicationreviewquestionnairetables {
              id
              question
              section
              answer_type
            }
          }
        }`
      },
      update: data => (data.programs_applicationformstable.length > 0 ? data.programs_applicationformstable : [{ title: '' }]),
    },
  },
}
</script>
