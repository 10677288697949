<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search assignments"
          type="text"
        />
        <b-button
          v-b-modal.applicationModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Assignment
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              dropright
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="`/programs/assignment/${props.row.id}`">
                <feather-icon
                  class="mr-50"
                  icon="EyeIcon"
                />
                <span>View Assignment</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedRow = props.index; $bvModal.show('partnerModal')"
              >
                <feather-icon
                  class="mr-50"
                  icon="UserIcon"
                />
                <span>Assign Mentors</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedRow = props.index; $bvModal.show('startupModal')"
              >
                <feather-icon
                  class="mr-50"
                  icon="BoxIcon"
                />
                <span>Assign Startups</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  class="mr-50"
                  icon="CopyIcon"
                />
                <span>Duplicate</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="applicationModal"
      size="lg"
      title="Assignment Form"
      ok-title="Save"
      @ok="addAssignment"
    >
      <b-row>
        <b-col md="5">
          <b-form-group
            label="Title"
            label-size="sm"
          >
            <b-form-input
              v-model="assignmentTitle"
              type="text"
              placeholder="Assignment Title"
              required
            /></b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Type"
            label-size="sm"
          ><b-form-input
            v-model="assignmentType"
            type="text"
            placeholder="Assignment Type"
            required
          /></b-form-group></b-col>
        <b-col md="4">
          <b-form-group
            label="Operation"
            label-size="sm"
          ><treeselect
            v-model="selectedOperation"
            :normalizer="normalizer"
            :options="operations"
            placeholder="Select Phase, Subphase or Activities"
          /></b-form-group></b-col>
      </b-row>
      <hr>
      <form-builder
        v-model="applicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
  </b-overlay>
</template>
<script>

import {
  BButton, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import vSelect from 'vue-select'
import FormBuilder from '@/views/programs/programManager/components/assignmentManager/FormBuilder.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BOverlay,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    FormBuilder,
    // vSelect,
    Treeselect,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Assignment',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Operation',
          field(row) {
            if (!row.programs_operationstable) return '-'
            return row.programs_operationstable.title || '-'
          },
        },
        {
          label: 'Operation Type',
          field(row) {
            if (!row.programs_operationstable) return '-'
            return row.programs_operationstable.operations_type || '-'
          },
        },
      ],
      rows: [],
      assignmentTitle: null,
      selectedOperation: null,
      assignmentType: null,
    }
  },
  computed: {
    assignPartner: {
      get() {
        if (this.rows.length < 1) {
          return []
        }
        return Object.keys(this.rows[this.selectedRow].programs_partnerassignmenttables)
          .map(key => this.rows[this.selectedRow].programs_partnerassignmenttables[key].partner_id)
      },
      set(e) {
        this.rows[this.selectedRow].programs_partnerassignmenttables = e.map(val => ({
          partner_id: val,
          assignment_id: this.rows[this.selectedRow].id,
        }))
      },
    },
    assignStartup: {
      get() {
        if (this.rows.length < 1) {
          return []
        }
        return Object.keys(this.rows[this.selectedRow].programs_startupassignmenttables)
          .map(key => this.rows[this.selectedRow].programs_startupassignmenttables[key].participant_id)
      },
      set(e) {
        this.rows[this.selectedRow].programs_startupassignmenttables = e.map(val => ({
          participant_id: val,
          assignment_id: this.rows[this.selectedRow].id,
        }))
      },
    },
    linearizeFormBuilder() {
      const obj = []
      this.applicationForm.forEach(e => e.items.forEach(f => obj.push({
        section: e.title || null,
        question: f.question || null,
        input_type: f.input_type || 'Short text',
      })))
      return obj
    },
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    addAssignment() {
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [programs_assignmentquestionstable_insert_input!]!) {
                        insert_programs_assignmenttable_one(object: {title: ${this.assignmentTitle},type: ${this.assignmentType}, operations_id: ${this.selectedOperation}, programs_assignmentquestionstables: {data: $data}}) {
                          id
                        }
                      }`,
          variables: {
            data: this.linearizeFormBuilder,
          },
          update: () => {
            this.$apollo.queries.rows.refetch()
          },
        },
      )
      this.assignmentTitle = null; this.selectedOperation = null; this.applicationForm = null; this.assignmentType = null
    },
    updatePartner() {
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_partnerassignmenttable_insert_input!]!) {
          delete_programs_partnerassignmenttable(where: {assignment_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_partnerassignmenttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_partnerassignmenttables,
        },
      })
    },
    updateStartup() {
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_startupassignmenttable_insert_input!]!) {
          delete_programs_startupassignmenttable(where: {assignment_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_startupassignmenttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_startupassignmenttables,
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_assignmenttable(where: {programs_operationstable: {program_id: {_eq: ${this.$route.params.id}}}}) {
            id
            title
            type
            programs_operationstable {
              title
              operations_type
            }
            status
            programs_partnerassignmenttables {
              partner_id
              assignment_id
            }
            programs_startupassignmenttables {
              id
              participant_id
              assignment_id
            }
          }
        }`
      },
      update: data => data.programs_assignmenttable,
    },
    partners: {
      query() {
        return gql`
        {
          networks_basicinfo(where: {programs_partnerstables: {program_id: {_eq: ${this.$route.params.id}}}}) {
            name
            id
          }
        }`
      },
      update: data => data.networks_basicinfo,
    },
    startups: {
      query() {
        return gql`
        {
          programs_applicantstable(where: {program_id: {_eq: ${this.$route.params.id}}, programs_startupparticipants: {id: {_is_null: false}}}) {
            name
            id
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
    operations: {
      query() {
        return gql`{
            programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "Phase"}}) {
                  id
                  title
                  programs_operationstables(where: {operations_type: {_eq: "SubPhase"}}) {
                    id
                    title
                    programs_operationstables {
                      id
                      title
                    }
                  }
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>
