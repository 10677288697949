<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >

      <app-collapse-item
        class="text-left"
        title="Add Partner"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Partner"
              label-for="partner"
            >
              <v-select
                id="partner"
                v-model="partner.id"
                :options="networks"
                label="name"
                :reduce="option => option.id"
                placeholder="Select Partner"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Designation"
              label-for="partnerDesignation"
            >
              <b-form-input
                id="partnerDesignation"
                v-model="partner.designation"
                placeholder="Designation of partner"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="mt-2"
              variant="outline-primary"
              @click="addPartner"
            >
              <feather-icon
                class="mr-25"
                icon="PlusIcon"
              />
              Add
            </b-button>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>
    <b-overlay
      :show="$apollo.loading"
      spinner-variant="primary"
    ><b-card
      class="mt-2"
    >
      <b-card-title class="lead">
        Assign Partner
      </b-card-title>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                dropright
                no-caret
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
              >
                <template v-slot:button-content>
                  <feather-icon
                    class="text-body align-middle"
                    icon="MoreVerticalIcon"
                    size="16"
                  />
                </template>
                <b-dropdown-item
                  variant="danger"
                  @click="deletePartner(props.row.id)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="TrashIcon"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card></b-overlay>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BButton,
  BFormInput,
  BCard,
  BCardTitle,
  BCol,
  BFormGroup,
  BRow, BPagination, BFormSelect, BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'

export default {
  components: {
    BButton,
    BFormInput,
    BCard,
    BCardTitle,
    BCol,
    BFormGroup,
    BRow,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  data() {
    return {
      pageLength: 5,
      partner: {
        id: null,
        designation: null,
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          width: '95px',
        },
        {
          label: 'Name',
          field(row) {
            if (!row.networks_basicinfo) return '-'
            return row.networks_basicinfo.name || '-'
          },
        },
        {
          label: 'Startup',
          field(row) {
            return row.designation || '-'
          },
        },
      ],
    }
  },
  methods: {
    addPartner() {
      // TODO: Add spinner
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_partnerstable_one(object: {network_id: ${this.partner.id}, designation: ${this.partner.designation}, program_id: ${this.$route.params.id}}) {
            id
          }
        }`,
        update: () => {
          this.partner.id = null
          this.partner.designation = null
          this.$apollo.queries.rows.refetch()
        },
      })
    },
    deletePartner(id) {
      // TODO: Add confirmation prompt & spinner
      this.$bvModal.msgBoxConfirm('Do you want to delete this record? This action isn\'t reversible!', {
        title: 'Are you sure?',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        centered: true,
      })
        .then(value => {
          if (value) {
            this.$apollo.mutate({
              mutation: gql`mutation {
                              delete_programs_partnerstable_by_pk(id: ${id}) {
                                network_id
                                id
                              }
                            }`,
              update: () => {
                this.$apollo.queries.rows.refetch()
              },
            })
          }
        })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_partnerstable(where: {program_id: {_eq: ${this.$route.params.id}}}){
            id
            networks_basicinfo {
              name
            }
            designation
          }
        }`
      },
      update: data => data.programs_partnerstable,
    },
    networks: {
      query() {
        return gql`
        {
            networks_basicinfo {
              id
              name
            }
        }`
      },
      update: data => data.networks_basicinfo,
    },
  },
}
</script>
